/**
 * stationlocation schema (relationship)
 * Mixed of Station and Location Schema.
 * pK is stationId, sK is LocationId
 */

import { roleSuperAdmin } from '@/model/accessRolesModel';
import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getBooleanTypeReferences } from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { getLocationTypeReferences } from './locationType';

const stationLocationFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the station-location relationship',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const stationLocationFieldStationId = {
  name: 'pK',
  type: datatypes.string,
  description: 'Unique id of the station',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
  getValueFromId(stationLocationId) {
    const splitted = stationLocationId.split(':');
    if (splitted.length !== 2) {
      throw new ReferenceError(`Invalid Id format. Expected [pK:sK] ; read [${stationLocationId}].`);
    }
    return splitted[0];
  },
};

const stationLocationFieldLocationId = {
  name: 'sK',
  type: datatypes.string,
  description: 'Unique id of the location',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
  getValueFromId(stationLocationId) {
    const splitted = stationLocationId.split(':');
    if (splitted.length !== 2) {
      throw new ReferenceError(`Invalid Id format. Expected [pK:sK] ; read [${stationLocationId}].`);
    }
    return splitted[1];
  },
};

const stationLocationFieldStationName = {
  name: 'stationName',
  type: datatypes.string,
  description: 'Name given to the station',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('stationlocation.fields.stationName', 1),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
  rules: [rules.required],
};

const stationLocationFieldStationParams = {
  name: 'stationParams',
  type: datatypes.json,
  description: 'Specific config parameters for the station',
  label: i18n.tc('stationlocation.fields.stationParams', 1),
  minLength: 0,
  maxLength: 4096,
  rules: [rules.isJson],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const stationLocationFieldStationIsActive = {
  name: 'stationIsActive',
  type: datatypes.boolean,
  description: 'The station is currently active.',
  label: i18n.tc('stationlocation.fields.stationIsActive', 1),
  references: getBooleanTypeReferences(),
  rules: [],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const stationLocationFielLocationType = {
  name: 'locationType',
  type: datatypes.string,
  description: 'Type of the location (DOOR/ZONE)',
  label: i18n.tc('stationlocation.fields.locationType', 1),
  references: getLocationTypeReferences(),
  rules: [rules.required],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const stationLocationFieldFloorNumber = {
  name: 'floorNumber',
  type: datatypes.integer,
  default: 0,
  description: 'Floor number of the location.',
  label: i18n.tc('stationlocation.fields.floorNumber', 1),
  rules: [rules.required, rules.number],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const stationLocationFieldReference = {
  name: 'reference',
  type: datatypes.string,
  description: 'Reference of the location (official name)',
  default: '',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('stationlocation.fields.reference', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const stationLocationFieldName = {
  name: 'name',
  type: datatypes.string,
  description: 'Use name of the location',
  default: '',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('stationlocation.fields.name', 1),
  rules: [],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const stationLocationFieldCapacity = {
  name: 'capacity',
  type: datatypes.integer,
  description: 'Capacity of the location (Zone).',
  label: i18n.tc('stationlocation.fields.capacity', 1),
  default: 0,
  rules: [rules.required, rules.number],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const stationLocationFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('stationlocation.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const stationLocation = {
  fields: [
    stationLocationFieldStationId,
    stationLocationFieldStationName,
    stationLocationFieldStationParams,
    stationLocationFieldStationIsActive,
    stationLocationFieldLocationId,
    stationLocationFielLocationType,
    stationLocationFieldFloorNumber,
    stationLocationFieldReference,
    stationLocationFieldName,
    stationLocationFieldCapacity,
    stationLocationFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleSuperAdmin],
    update: [],
    delete: [roleSuperAdmin],
  },
};

export default stationLocation;
export {
  stationLocation,
  stationLocationFieldId,
  stationLocationFieldStationId,
  stationLocationFieldStationName,
  stationLocationFieldStationParams,
  stationLocationFieldStationIsActive,
  stationLocationFieldLocationId,
  stationLocationFielLocationType,
  stationLocationFieldFloorNumber,
  stationLocationFieldReference,
  stationLocationFieldName,
  stationLocationFieldCapacity,
  stationLocationFieldItemStatus,
};
