import i18n from '../i18n';
import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';
import { stationLocationFieldLocationId } from '../model/stationlocation';

export default {
  name: 'stationslocations',

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  delete(itemId) {
    return axiosInstance.delete(`/${this.name}/${encodeURIComponent(itemId)}`);
  },

  getList(getAll = false) {
    let url = `/${this.name}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getListForLocation(locationId, getAll = false) {
    if (!locationId) {
      throw new Error(i18n.tc('errMsg.undefinedValue_fmt', 1, { data: i18n.t('door.fields.id') }));
    }
    let url = `/${this.name}`;
    url += `?${stationLocationFieldLocationId.name}=${encodeURIComponent(locationId)}`;
    if (getAll === true) url += `&${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  setDefaultValues(item) {
    const result = { ...item };
    if (item.stationIsActive === null) result.stationIsActive = true;
    try {
      result.stationParams = JSON.parse(JSON.stringify(item.stationParams));
    } catch (error) { // TODO : test and adapt according to error type
      result.stationParams = item.stationParams;
    }
    if (item.itemStatus === null) result.itemStatus = itemStatusValues.default;
    return result;
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
